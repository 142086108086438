.cotuong {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100vh;
}
@media all and (max-width: 959px) {
  .cotuong {
    flex-direction: column;
  }
}
.column {
  display: flex;
}

.column_left {
  flex: 2;
}
.column_right {
  flex: 1;
}
.menu {
  margin: 0 auto;
  width: 90%;
}
.board {
  display: -ms-grid;
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  grid-template-rows: auto;
  border: 5px solid #999999;
  margin: 0 auto;
}
::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
@media all and (max-height: 768px) {
  .board {
    /* max-width: 520px; */
    width: fit-content;
  }
}
.board.finished {
  opacity: 0.25;
  pointer-events: none;
}
.board .field:hover {
  -webkit-box-shadow: inset 0px 0px 0px 3px #3366cc;
  -moz-box-shadow: inset 0px 0px 0px 3px #3366cc;
  box-shadow: inset 0px 0px 0px 3px #3366cc;
}
.board .lastMove {
  -webkit-box-shadow: inset 0px 0px 0px 2px #ff0033;
  -moz-box-shadow: inset 0px 0px 0px 2px #ff0033;
  box-shadow: inset 0px 0px 0px 2px #ff0033;
}
.board .moveFrom {
  -webkit-box-shadow: inset 0px 0px 0px 3px #3366cc;
  -moz-box-shadow: inset 0px 0px 0px 3px #3366cc;
  box-shadow: inset 0px 0px 0px 3px #3366cc;
}
.board .moveTo {
  -webkit-box-shadow: inset 0px 0px 0px 2px #33cc33;
  -moz-box-shadow: inset 0px 0px 0px 2px #33cc33;
  box-shadow: inset 0px 0px 0px 2px #33cc33;
}

.overlay {
  background-image: url(./img/game_over.jpg);
  background-size: 40% 20%;
  background-repeat: no-repeat;
  background-position: center;
  margin: auto;
  position: relative;
}

.loading-spinner {
  font-size: 30px;
  color: red;
  position: absolute;
  z-index: 9999;
  left: 40%;
  top: 46%;
  margin: 0px auto;
}

body {
  padding: 0;
  margin: 0;
  font-size: 20px;
  color: white;
  background-color: lightskyblue;
}
a:visited {
  color: #4caf50;
}
a:link {
  color: #4caf50;
}

body .field {
  display: flex;
  background-size: 100% 100%;
  align-items: center;
  justify-content: center;
  height: var(--field-width, min(8vw, 8vh));
  width: var(--field-width, min(8vw, 8vh));
  font-size: 30px;
  background-color: white;
  padding: 5px;
}

.field.white .sign,
.field.black .sign {
  border-radius: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  z-index: 1;
  position: absolute;
  border: 1px double darkgray;
  background-color: white;
  width: 60%;
  height: 60%;
}

.sign.rong {
  font-family: -webkit-pictograph;
}

.field .crossing {
  width: 100%;
  height: 100%;
  position: absolute;
}

.field .top,
.field .bottom {
  width: 100%;
  height: 50%;
  display: flex;
}

/* .field .top {
  border-bottom: 1px solid darkgray;
}

.field .bottom {
  border-top: 1px solid darkgray;
} */

.field .left {
  width: 50%;
  height: 100%;
  border-right: 1px solid darkgray;
  border-bottom: 1px solid darkgray;
  float: left;
}

.field .right {
  width: 50%;
  height: 100%;
  /* border-left: 1px solid darkgray; */
  border-bottom: 1px solid darkgray;

  float: left;
}

.field.first-column .left {
  border-bottom: none;
}

.field.first-row .top .left {
  border-right: none;
}

.field.first-row .top .right {
  border-left: none;
}

.field.last-row .bottom .left {
  border-right: none;
}

.field .bottom-left-marker {
  position: absolute;
  bottom: 60%;
  left: 60%;
  border-bottom: 2px double darkgray;
  border-left: 2px double darkgray;
  z-index: 0;
  width: 10px;
  height: 10px;
}

.field .bottom-right-marker {
  position: absolute;
  bottom: 60%;
  right: 60%;
  border-bottom: 2px double darkgray;
  border-right: 2px double darkgray;
  z-index: 0;
  width: 10px;
  height: 10px;
}

.field .top-right-marker {
  position: absolute;
  top: 60%;
  right: 60%;
  border-top: 2px double darkgray;
  border-right: 2px double darkgray;
  z-index: 0;
  width: 10px;
  height: 10px;
}

.field .top-left-marker {
  position: absolute;
  top: 60%;
  left: 60%;
  border-top: 2px double darkgray;
  border-left: 2px double darkgray;
  z-index: 0;
  width: 10px;
  height: 10px;
}

.field .crossing .bottom .left,
.field .crossing .bottom .right {
  border-bottom: none;
}

.castle,
.castle-mirrored {
  width: 282%;
  height: 200%;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  pointer-events: none;
}

.castle:after {
  content: "";
  position: absolute;
  border-top: 1px double darkgray;
  width: 100%;
  transform: rotate(45deg);
  transform-origin: 0% 0%;
}

.castle-mirrored:after {
  content: "";
  position: absolute;
  border-top: 1px double darkgray;
  width: 100%;
  transform: rotate(135deg);
  transform-origin: 0% 0%;
}

.field.last-column .right {
  border-bottom: none;
}

.field.white {
  color: #33cc33;
}
.field.black {
  color: black;
}

.field.white .sign {
  border-color: #33cc33;
}
.field.black .sign {
  border-color: black;
}

.field .river {
  border-top: aquamarine;
  opacity: 0.5;
  width: 100%;
  height: 100%;
  z-index: 1;
  position: absolute;
}

.field.bottom-river .bottom .left {
  border-right: none;
}
.field.top-river .top .left {
  border-right: none;
}

.field .palace {
  background-color: orange;
  opacity: 0.5;
  width: 100%;
  height: 100%;
  z-index: 1;
  position: absolute;
}

button {
  background-color: #4caf50;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 20px;
  width: 100%;
}
button:hover {
  background-color: #006400;
}
button:disabled {
  opacity: 0.5;
  pointer-events: none;
}
.menu #history {
  background-color: #f0f7fb;
  line-height: 18px;
  overflow: hidden;
  margin: 10px 0px 10px 0px;
  text-align: center;
  padding: 5px;
  color: #666666;
  border-radius: 10px;
  overflow-y: scroll;
  height: fit-content;
  max-height: 200px;
  font-size: 18px;
}
.menu #copyright {
  margin: 20px 0;
  bottom: 5px;
  font-size: 12px;
  text-align: center;
}
.menu #confirmation {
  margin-top: 35px;
}
.level {
  padding: 5px;
  color: white;
  display: flex;
  align-items: center;
  flex-direction: row;
  display: inline-flex;
  margin-left: 20px;
}

.level.selected {
  color: green;
}

.level input {
  width: 20px;
  height: 20px;
  margin-left: 10px;
}
/* Customize the label (the level_wrapper) */

#soundContainer {
  width: 100%;
  display: flex;
  align-items: center;
}

#sound {
  width: 20px;
  height: 20px;
  margin: 10px;
}
.loading {
  cursor: wait;
}

.queen-chess .field {
  border: 1px solid darkgray;
  padding: 10px;
}

.queen-chess .field * {
  border: none;
}

.queen-chess .castle-mirrored:after,
.queen-chess .castle:after {
  content: none;
}

.queen-chess .field:nth-of-type(2n) {
  background-color: lightsalmon;
}

.kingchessmarker .field:nth-of-type(2n) .placeholder {
  background-color: lightsalmon;
  width: 8px;
  height: 8px;
  z-index: 1;
  top: 49%;
  left: 49%;
}
